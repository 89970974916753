<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',

  created() {
    localStorage.setItem('Language', 'en')
  }
}
</script>
<style lang="scss">
* {
  font-family: PingFangSC-Medium, PingFang SC !important;
}
input[type='password']::-ms-reveal {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: #182040 !important;
  color: #bfc8e2 !important;
  -webkit-box-shadow: 0 0 0 400px #182040 inset;
  -webkit-text-fill-color: #bfc8e2 !important;
}
</style>
