module.exports = {
  msg: {
    one: 'Первый в истории; безопасная и проверенная платформа смарт-контрактов',
    meet: 'Встретить будущее',
    meetExplain: '72 часа обратного отсчета «встретить будущее» до розыгрыша, когда нет узла в течение 72 часов, время уменьшается до нуля, и «встретить будущее» производит розыгрыш. Но для каждого ввода ETH, время будет соответственно откладывать на 2 часа, а максимальное время не будет превышать 72 часов. Максимальное накопление этого приза составляет 5000 ETH. После розыгрыша приза бонус будет получен от последних 100 вложенных узлов пропорционально весу инвестиций.',
    countdown: 'Обратный отсчёт времени',
    getToken: 'Текущий этап',
    currentStage: 'Текущийэтап',
    currentStage2: 'Фонд Ангелов,',
    Remaining: 'Остающийся лимит на данном этапе',
    ObtainedToken: 'Полученный пропуск',
    TokenBalance: 'Баланс пропусков',
    AccountBalance: 'Баланс счета',
    buy: 'Купить',
    participate: 'Я хочу участвовать',
    participateExplain1: 'Децентрализованный маркетинг поддерживается революционной технологией смарт-контрактов.',
    participateExplain2: 'Код смарт-контракта проекта Vane полностью открыт, ',
    participateExplain3: 'поэтому вы можете быть полностью уверены в безопасности и долгосрочной работе проекта.',
    game: 'Игрa',
    account_balance: 'Баланс счета',
    link: 'Ссылка',
    myReferral: 'Мой рекомендатель',
    myDirectReferrals: 'Количество моей прямой рекомендателей',
    myInvitationLink: 'Моя пригласительная ссылка',
    myID: 'Мой ID',
    myAddress: 'Мой адрес',
    copyInvitationLink: 'Скопировать ссылку-приглашение',
    Rules: 'Правила',
    RulesPlay: 'Правила игры',
    RulesExplain1: 'USDT,участвующий в игре, получит максимальный возврат в 3 раза. По сумме участия в игре и изменению рынка, блок рассчитывается как ежедневный статический дивиденд, процент дивидендов составляет около 5‰ после увеличения. ',
    RulesExplain2: 'Динамически есть награда дивидендов, награда класса, награда ежедневного рейтинга, награда взвешивания V5 и различные награды.',
    RulesExplain3: 'Продажа пропусков: разнообразная экология, продолжение привлечения поклонников, созданиеконсенсуса и повышение стоимости.',
    TodayAward: 'Сегодняшний рейтинг',
    YesterdayRankingAward: 'Награда за вчерашний рейтинг',
    YesterdayAward: 'награда',
    Rank: 'Рейтинг',
    Performance: 'Достижения',
    ID: 'ID',
    ViewYesterdays: 'Посмотреть награду за вчерашний рейтинг',
    Yesterday: 'Награда за вчерашний рейтинг',
    Statistics: 'Статистика',
    MyLevel: 'Мой уровень',
    UnsettledEarnings: 'Неурегулированный доход',
    CashableIncome: 'Кэшируемый пдоход',
    StaticIncome: 'Статический доход',
    DynamicIncome: 'Динамический доход',
    SettledIncome: 'Расчетный доход',
    outNeeds: 'Исключение динамического дохода и статического дохода все еще',
    balance: 'Расчеты',
    TotalTSokenSales: 'Общий объем продаж пропусков',
    TotalNumberBurned: 'Общее количество сожженных пропусков',
    EntryFundsToday: 'Входящие средства сегодня',
    ViewDetailedData: 'Посмотреть подробные данные',
    DirectNode: 'Переходи сразу к общему результату',
    RegionalPerformance: 'Достижения большого региона',
    CommunityPerformance: 'остижения малого региона',
    DividendAward: 'Дивиденды',
    LevelAward: 'Награды уровня',
    DailyRankingsAward: '每日排名奖',
    commonProblem: 'Общие проблемы',
    problem1: '1. Что такое смарт-контракт и в чем его преимущества?',
    answer1: 'Умные контракты - это алгоритм в крипто-валютной блокировке, и в нашем случае, Коинан - это первый выбор, где мы можем создавать умные контракты.Основной целью таких контрактов является автоматизация отношений, дающая возможность автоматизировать выполнение обещаний.',
    problem2: '2. Что такое Vane?',
    answer2: `Vane была создана группой энтузиастов криптографии, которые являются членами сообщества и не имеют никаких особых привилегий. Vane - это строка кода, построенная для автоматического запуска на цепочке Coin On ----. Технический термин называется "умный контракт" - это наиболее безопасный и гибкий и надежный вид алгоритма кода или работы компьютерной логики, построенный на блок-брелоке. Cryptocurrency также является одной из первых цифровых валют в мире, которая внедрила концепцию и применение "умных" контрактов на блок-блок-схеме. Распространяются "умные" контракты, как криптокорреспонденты. Они работают строго в установленном порядке и не имеют возможности быть подделанными. Весь логический код "умного" контракта размещен на блок-схеме, а все вычисления выполняются миллионами компьютеров по всему миру. Это гарантирует отсутствие риска взлома "умного" контракта и остановки проекта.Это потому, что он хранится децентрализованно на блок-цепочке.`,
    problem3: '3. Кто будет управлять платформой?',
    answer3: 'В проекте Vane нет администраторов. Был один создатель, который загрузил код контракта в крипто-валютный блок-блокнот "Монета". С тех пор интеллектуальный контракт стал частью всей сети и поддерживается шахтерами. Никто не имеет права влиять на заключение, расторжение или прекращение действия "умного" контракта. Любые несанкционированные попытки изменить его будут отклонены из-за несоответствия с предыдущей копией в блок-цепочке.',
    problem4: 'Я потеряю деньги при неудаче проекта?',
    answer4: 'Не будет! Смарт-контракты абсолютно прозрачны, и все данные записываются в цепочку блокчейн. Никакого мошенничества. Проект не может провалиться.Пока существует блокчейн, он будет работать, даже если сайт закрыт.',
    yhzj: 'Банковские средства',
    hysj: 'Контрактный аудит',
    jrwmdb: 'Присоединяйтесь к нашей телеграмме',
    logo: 'Загрузка LOGO',
    save: 'Сохранить как PNG',
    ReplicationSuccess: 'Успешная репликация',
    notSufficientFunds: 'недостаток средств',
    xian: 'Диапазон покупок от 1 до 100',
    AfterTheStart: 'Начинаем обратный отсчет',
    ForTheEnd: 'Распродажа завершена',
    peopleCounting: 'численность'

  }
}
