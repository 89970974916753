module.exports = {
  msg: {
    yqm: '邀請碼：',
    yqurl: '邀請鏈接：',
    Tokens: '代幣',
    Subscription: '認購 第一輪',
    progress: '代幣銷售進度',
    Total: '本輪發行總量：',
    subscribed: '已認購總數量：',
    Endsale: '銷售結束',
    AvailableBalance: '可用余額：',
    Obtain: '獲得KYS',
    willreceive: '輸入您的認購金額，系統將會自動計算出您將收到的代幣數量',
    EnterYourAmount: '輸入USDT金額或者需要認購KYS數量，計算器會自動幫您計算出可以兌換的KYS數量或者相應所需的USDT金額',
    TokenSale: '代幣銷售',
    ASDtoken1: 'KYS代幣銷售',
    ASDtoken2: '條款和條件',
    Participate: '參與',
    days: '天',
    hours: '小時',
    minutes: '分',
    seconds: '秒',
    Y: '於',
    Start: '開始',
    End: '結束',
    Exchangerate: '匯率',
    TotalIssuance: '發行總量',
    TokenName: '代幣名稱',
    TokenSymbol: '代幣符號',
    TotalCirculation: '總發行量',
    TechnicalGroups: '技術團體',
    Foundation: '基金會',
    PreSale: '預售',
    Presale1: '預售',
    Ecology: '生態',
    Not1ASD: '不能小於1KYS',
    Please: '請勾選條款',
    payment: '輸入支付密碼',
    ConfirmPayment: '確認支付',
    Entered: '支付密碼輸入不正確',
    Insufficient: '賬戶余額不足',
    Oversubscription: '認購超額，剩余可認購',
    Kaiyuan: '開元公鏈',
    ended: '活動未開啟或已結束',
    MyAssets: '我的資產',
    withdrawalPage: '提幣頁面',
    TotalAssets: '總資產：',
    Currency: '幣種',
    Available: '可用',
    Freeze: '凍結',
    Operation: '操作',
    Withdrawal: '提幣',
    // FinancialRecords: '財務記錄',
    FinancialRecords: '記錄',
    Time: '時間',
    Type: '類型',
    Quantity: '數量',
    Status: '狀態',
    SystemIncrease: '系統增加',
    Recharge: '充值',
    SuccessfulTransaction: '交易成功',
    Transaction: '交易失敗',
    ModifyLogin: '修改登錄密碼',
    Modifypayment: '修改支付密碼',
    Logout: '退出登錄',
    DepositCurrency: '充值幣種',
    ChainName: '鏈名稱',
    CopyAddress: '復制地址',
    CopySuccess: '復制成功',
    WarmReminder: '溫馨提示：',
    minimum1: '最小充值金額：',
    minimum2: '小於最小金額的充值將不會入賬',
    retrieved: '請勿向上述地址充值任何非USDT資產，否則資產將不可找回。',
    losses: '請您務必填寫並仔細核對地址標簽，這是您賬戶的唯一標識，避免造成不必要的損失。',
    WithdrawalCurrency: '提幣幣種',
    PleaseAddress: '請輸入或粘貼提幣地址',
    AvailableAuantity: '可提數量：',
    HandlingFee: '手續費',
    ActualArrival: '實際到賬',
    minimumBalance1: '最小提幣數量：',
    minimumBalance2: '，最大提幣金額不得超過賬戶餘額。',
    ConfirmWithdrawal: '確認提幣',
    PleaseEnterAddress: '請輸入地址',
    PleaseAmount: '請輸入提幣數量',
    InsufficientBalance: '余額不足',
    Withdrawal50: '提幣必須大於10',
    SuccessfulSubmission: '提交成功',
    processing: '等待處理',
    ww: '提幣撤銷',
    All: '全部',
    to50: '數量必須大於等於 50',
    ResetLoginPassword: '重設登錄密碼',
    changePaymentPassword: '修改支付密碼',
    loginPage: '登錄頁面',
    IfYou: '如果您忘記了密碼，那麼我們會通過電子郵件向您發送說明以重置密碼',
    YourEmail: '您的郵件地址',
    CodeYouReceived: '輸入您收到的驗證碼',
    GetVerificationCode: '獲取驗證碼',
    SetNewPassword: '設置您的新密碼',
    OK: '確定',
    BackToLogin: '返回登錄',
    PasswordReset: '密碼重置成功，請使用新密碼登錄',
    UserNot: '用戶不存在',
    Login: '登錄',
    Welcome: '歡迎登錄 KYS',
    YourMail: '您的郵件',
    YourLoginPassword: '您的登錄密碼',
    LogIn: '登錄',
    NoAccountYet: '還沒有賬號?',
    RegisterNow: '立即註冊',
    ForgotPassword: '忘記密碼?',
    IncorrectPassword: '密碼不正確',
    Register: '註冊',
    Create: '建立新的KYS賬戶',
    YourName: '您的名字',
    YourMail1: '您的郵件',
    PleaseMail: '請輸入您的郵件',
    verificationCode: '輸入您收到的驗證碼',
    GetVerificationCode1: '獲取驗證碼',
    SetLogin: '設置您的登錄密碼',
    RepeatPassword: '重復您的登錄密碼',
    Setpassword: '設置您的支付密碼',
    SetInvitationCode: '請輸入邀請碼',
    agreement: '我已同意條款和條件以及隱私協議',
    CreateAccount: '創建賬號',
    Verification: '驗證碼已發送，請註意查收',
    PleaseVerificationCode: '請輸入您的驗證碼',
    plusLetters: '密碼必須由6-12位數字加字母組成',
    ReEnter: '再次輸入密碼不一致',
    PleaseSet: '請先設置登錄密碼',
    passwordAgain: '請再次輸入登錄密碼',
    PleaseFirst: '請先設置支付密碼',
    InvitationCode: '請先設置邀请码',
    PleaseName: '請輸入您的名字',
    Incorrect: '驗證碼不正確',
    exists: '此賬號已存在',
    Registered: '註冊成功',
    PleasePaymentPassword: '請設置您的新支付密碼',
    Modification: '確認修改',
    ResetPaymentPassword: '重設支付密碼',
    Team: '團隊',
    WhitePaper: '白皮書',
    Language: '語言',
    Traditional: '繁體',
    English: '英文',
    Solution: '解決方案',
    Platform: '平臺',
    PlatformPage: '平臺頁面',
    banner1: '引領下一代分佈式網絡存儲',
    banner:
      'CNFS是領先的去中心化雲存儲平台。沒有註冊，沒有服務器，沒有可信任的笫三方。 CNFS利用區塊鏈技術創建了一個比傳統雲存儲提供商更強大的，更實惠的數據存儲市場。',
    Acquiring: '購買KYS',
    theSuccess: '参与成功',
    SystemCore: '系統核心',
    SystemCore1:
      'CNFS的商業體系是一個完整的生態，包括公鏈、商業體系、Token治理機制等。在一個大的商業生態體系中，整體架構分為三部分，客戶端、Totem中臺、CNFS、區塊鏈適配器。',
    SystemCore2:
      'CNFS（英文Genesis Blockchain）是一個開源的有智能合約功能的公共區塊鏈平臺，通過其專用加密貨幣KYS通證（Totem通）提供去中心化的虛擬機（Virtual Machine）來處理點對點合約。',
    SystemCore3:
      '區塊鏈電商體系是基於區塊鏈技術的價值互聯網電商平臺。通過CNFS作為商業網絡的底層網絡，通過業務流、信息流、資金流、物流上鏈的方式實現全過程可溯源的商業體系。',
    SystemCore4:
      'CNFS 通過分散的網絡加密和分發您的文件。您控制您的私人加密密鑰並擁有您的數據。與傳統的雲存儲提供商不同，沒有外部公司或第三方可以訪問或控制您的文件。',
    SystemCore5:
      '平均而言，CNFS 的去中心化雲存儲成本比現有云存儲提供商低 90%。在 CNFS 上存儲 1TB 文件的費用約為每月 1-2 美元，而在 Amazon S3 上則為 23 美元。',
    SystemCore6:
      'CNFS 在全球節點上分發和存儲冗余文件段，消除任何單點故障並確保與傳統雲存儲提供商相媲美的正常運行時間。',
    LearnMore: '了解更多',
    Decentralized: '完全私密',
    Backend: '更實惠',
    Market: '高度冗餘',
    GetPower: '得到力量',
    GiveStrength: '給予力量',
    Purchase: '購買',
    ApplicationModule: '準備好構建您的應用程序了嗎？',
    ApplicationModuleTitle:
      '使用 CNFS 區塊鏈，CNFS 創建了一個去中心化的存儲市場，存儲供應商在其中競爭您的業務，從而使價格盡可能低。租戶使用 CNFS支付，也可以開采和交易。',

    ApplicationModule1: '易於使用',
    ApplicationModule2:
      '無需運行節點或錢包的去中心化存儲。 Skynet 還包括用於流行編程語言和 API 的 SDK，可與您現有的應用程序無縫集成',
    ApplicationModule3: '快速地',
    ApplicationModule4:
      '天網的速度可與中心化提供商競爭並超越所有去中心化產品。一個典型的天網下載在 500毫秒內開始，並且可以以高達 1 Gbps 的速率進行流式傳輸',
    ApplicationModule5: '免費使用',
    ApplicationModule6: '專注於構建，而不是開銷服務器成本。當用戶擁有他們的數據時，不會要求開發人員為其付費。',
    ApplicationModule7: '持久性',
    ApplicationModule8:
      '您的 skapp 和數據保持實時，即使公司撤回您對其資源的訪問權限。您還可以將天網用作集中式提供商出現故障時的故障轉移站點',

    Applicationof: '產品溯源應用系統應用',
    benefits:
      '產品溯源系統借助區塊鏈技術，可實現品牌商、渠道商、零售商、消費者、監管部門、第三方檢測機構之間的信任共享，全面提升品牌、效率、體驗、監管和供應鏈整體收益。',
    Application: '物聯網交易系統應用',
    LowerPrice:
      '物聯網數據交易平臺，是公鏈應用的核心之一，在此平臺上，設備廠商可以建立采集數據、銷售數據的渠道；設備用戶可以通過此渠道提供個人數據以獲取收益；數據購買方，如廣告主，也可以利用此平臺精準定位用戶，獲取用戶畫像數據，並以更低的價格高效地達到傳播目的。',
    Supply: '供應鏈金融應用',
    Innovatively:
      '供應鏈金融應用系統基於區塊鏈技術的分布式存儲功能，天然具有信息不易被篡改、去中心化、開放化、可視化等特征，可有效解決傳統供應鏈金融中存在的諸多痛點，助力供應鏈金融打破瓶頸、創新發展',
    PublicChainRules: '技術架構',
    PublicChainRules2: '開放互聯網',
    PublicChainRules1:
      'CNFS是一個新型的基於區塊鏈的去中心存儲方案，實現去中心化存儲，並降低區塊鏈存儲成本。 CNFS的主要組成部分包括路由層，存儲層和接口層。路由層主要實現網絡間節點的交互和數據的傳輸；存儲層主要用於實現數據的分佈式存儲，對數據進行存儲管理；接口層給用戶提供使用區塊鏈的功能',
    PublicChainToken: '網絡升級',
    PublicChainToken2: '網絡升級',
    PublicChainToken1:
      '礦工幫助我們建立一個更加開放、具有彈性和值得信任的互聯網。 通過在CNFS網絡挖礦，您可以使存儲在網絡上的數據更加分佈式並且更普及。 CNFS挖礦，重新掌握數字化的未來',
    ConstantPass: '技術與功能',
    ConstantPass2: '開發者工具',
    ConstantPass1:
      'CNFS是一個可驗證的存儲市場的核心協議。我們的生態中有很多工具來簡化開發以及終端用戶的體驗。如果你對於其他區塊鏈較熟悉，有錢包，驗證工具和JS APIs等等更多來協助你開始開發。',

    assetOnChainServices:
      '為了提高去中心化程度，增強客戶資產的安全性以及開元公鏈泛商業生態系統的公信力， 開元公鏈泛商業生態系統將在未來上線應用跨鏈技術的開元公鏈。該公鏈將作為橋梁連接各個網絡節點，為節點會員提供實時清算，鏈上結算和資產上鏈服務。',
    TeamAndConsultants: '團隊與顧問',
    Together: '價值使我們團結在一起',
    Officer: 'CEO首席執行官',
    PhD: '美國賓夕法尼亞大學電子與通信工程博士；',
    Former: '原雅虎Yahoo!首席信息官；',
    Founder: 'Genesis Lab 實驗室創始人兼CEO；',
    Laboratory: '矽谷跨國聯合技術創新實驗室核心成員；',
    companies: '擁有超過15年的500強跨國企業 IT 高管經驗。',
    OperatingOfficer: 'COO首席運營官',
    Business: '紐約大學商學院工商管理碩士；',
    CoFounder: 'Genesis Lab 實驗室聯合創始人',
    America: '原紐約梅隆銀行北美地區首席運營官；',
    Institutions: '超過10年的金融科技機構運營管理經驗。',
    Technology: 'CTO首席技術官',
    experience: '畢業於斯坦福大學的計算機碩士，16年專業編程經驗；',
    Express: '美國運通公司（American Express）首席技術官與架構師；',
    community: '比特幣網絡社區早期核心成員；',
    world: '全球多家區塊鏈科技實驗室核心成員。',
    FinancialOfficer: 'CFO首席財務官',
    Double: '加拿大多倫多大學財務管理和企業管理雙學士；',
    Citibank: '曾就職於花旗集團（Citibank,N.A.）, 資深風險管理師與財務專家；',
    ChiefFinancial: '多家國際知名金融機構首席財務顧問；',
    Alliance: '矽谷金融科技聯盟核心成員。',
    CIOChief: 'CIO首席創新官',
    Major: '英國樸次茅斯大學市場管理專業；',
    conceptualDesign: '專註與產品體驗推廣與創新概念設計；',
    Product20: '20多家大型跨國企業產品體驗官；',
    SeniorConsultant: '英國皇家商業聯盟高級咨詢師。',
    ChiefEconomist: '首席經濟學家',
    Birmingham: '英國伯明翰大學碩士；',
    PCCW: '曾供職於微軟、PCCW等國際知名企業；',
    Investment: '新加坡區塊鏈應用與投資研究院核心成員。',
    Distinguished: '新加坡區塊鏈商學院特聘教授。',
    CSOChief: 'CSO首席戰略官',
    Minnesota: '明尼蘇達大學工商管理博士；',
    StrategicManagement: '聖保羅國際商學院戰略管理培訓專家；',
    FormerChief: '原薩夫拉銀行戰略委員會首席戰略官。',
    CISO: 'CISO首席信息安全官',
    Computer: '美國哥倫比亞大學計算機科學專業；',
    CISSP: 'CISSP國際信息系統安全認證專家；',
    audit: '原Telus安全實驗室，Sybase數據庫安全審計負責人；',
    Pacific: '世界500強企業新加坡及亞太地區首席信息安全官。',
    haveAccount: '已有賬號？',
    immediately: '立即登錄',
    agreed: '我已同意',
    termsConditions: '條款和條件',
    and: '以及',
    PrivacyAgreement: '隱私協議',
    retransmit: '重新發送',
    PleaseExchange: '請輸入兌換的',
    MinExchangeUsdt: '您好！種子輪最低認購額度300000USDT起',
    paymentCode: '請輸入支付密碼',
    theTerms: '請確認條款',
    PleaseEmailAddress: '請輸入正確的郵箱地址',
    NoRecord: '暫無記錄',
    Mentionddress: '提幣地址',
    MentionCurrency: '提幣數量',
    LongSave: '長按二維碼可保存',
    loading: '加載中...',
    RefreshSuccess: '刷新成功',
    ReleaseRefresh: '釋放即可刷新…',
    incorrect: '郵箱格式不正確',
    communicationsAtAnyTime:
      '通過提交電子郵件，即表示您同意CNFS使用您的個人信息與您聯繫我們的產品。你可以隨時退訂這些通訊',
    SUBMIT: '提交',
    Email: '郵箱',
    Subscribe: '訂閱時事通訊來了解創世鏈的更新和事件。',
    subscribeBtn: '訂閱',
    Chain: 'CNFS',
    round1: '第一輪',
    round2: '第二輪',
    round3: '第三輪',

    civilization: '商業元宇宙',
    civilization1: '—— 去中心化經濟體系',
    business:
      '這是一場基於區塊鏈與多種高科技相結合的產業革命，立足創新的展示形式、創新的組織架構、創新的商業模式、創新的分配機制，開創新的去中心化經濟體系——商業元宇宙。',
    era: '一個夢想，一個時代的希望',
    mechanism: '一個致力於打破資本主義商業桎梏的全新商業生態，不遺餘力的推動更加公平的社會分配機制的誕生。',
    example1: '歷時3年，重塑商業領域經濟模型',
    example2: '致力於打造面向全民的共享型經濟共贏體系',
    example3: '先進的技術與理念結合，推動區塊鏈技術深度賦能實體產業',
    AboutGNS: 'Genesis 區塊鏈3.0時代的卓越整合者',
    AboutGNS1: '大規模商用計算機',
    industry:
      'Genesis作為區塊鏈3.0時代的卓越整合者，致力於實現全球去中心化、無所有者、泛在的雲操作系統，為執行智能合約而生，承載快速增長的全球數字經濟。',
    Integrated:
      '與區塊鏈2.0的代表作ETH相比，Genesis具有更高的可擴展性、更快的交易速度、更穩定的全節點運行機制、更強的安全保護。這些創新而優秀的指標將使Genesis在去中心化應用（DApp）、去中心化金融（DeFi）、非同質化代幣（NFT）、分佈式存儲、供應鏈金融和存證等領域真正高效運作。',
    system: '去中心化組織製度',
    Distribution: '全民共享分配機制',
    Function: 'Genesis  生態',
    Computing: '商业',
    Commercial: 'DApp',
    Pledge: 'DeFi',
    Asset: '元宇宙',
    RouteMap: 'Genesis  路線圖',
    phase1: '2018',
    phase2: '2022',
    phase3: '2023',
    phase4: '2023',
    phase5: '2024',
    phase1Info: '公鏈啟動研發&nbsp;&nbsp;&nbsp;包括公鏈底層邏輯設置和實質性技術開發。',
    phase2Info: '公鏈測試網上線&nbsp;&nbsp;&nbsp;技術團隊完成基礎內測後邀請部分前期參與團隊進行性能測試。',
    phase3Info: '公鍊主網上線&nbsp;&nbsp;&nbsp;主網最終上線時間根據測試網上線後的實際測試情況或有細微調整。',
    phase4Info:
      '金融生態上線&nbsp;&nbsp;&nbsp;金融生態是指公鏈上線後同步需要部署的去中心化錢包、去中心化交易所等生態版塊。',
    phase5Info: '商業生態上線&nbsp;&nbsp;&nbsp;商業生態是指部署在公鏈上的去中心化商城、NFT創作平台、鏈遊等生態版塊。',
    Resources: 'Genesis  技術優勢',
    Resources1: '共識機制',
    Resources2: '高擴展性',
    Resources3: '集群效應',
    Resources4: '跨鏈兼容',
    Resources5: '高速結算',
    Resources6: '生態支撐',
    Resources1Info: 'Genesis公鏈獨創消費貢獻證明機制，確保每一個公鏈生態的參與者都可以獲得Genesis公鏈的區塊獎勵。',
    Resources2Info: 'Genesis兼容以太坊虛擬機，以太坊基礎運行智能合約都可以在Genesis上實現高效運轉。',
    Resources3Info:
      'Genesis獨創異構複合鏈技術，構造龐大公鏈集群，可以無限水平擴展公鏈性能和功能，子鏈側鏈不佔用公鏈效能。',
    Resources4Info: 'Genesis採用目前最先進的跨鏈技術，所有公鏈上的通證都可以跨鏈在Genesis公鏈上進行流通和交易。',
    Resources5Info: 'Genesis公鏈每6秒產生一個區塊，結算TPS=5000筆/秒，業內遙遙領先。',
    Resources6Info: 'Genesis采用最优秀的DPoC共识机制，深度结合实体产业，公链节点发展带自裂变效果。',
    SmartApi: 'Genesis API',
    Mall: 'DApp 商城',
    Wallets: '去中心化錢包',
    Exchange: '去中心化交易所',
    Mining: 'NFT交易',
    Redemption: '元宇宙',
    Sales: '通證出售',
    name: '名稱：',
    Stock: '股票代碼：',
    World: '加密世界',
    TokenImage: '通證符號圖像：',
    Price: '主網上線前價格：',
    dollar: '美元',
    Expected: '主網上線後預計：',
    Maximum: '最大出售數量：',
    Maximum1: '3000萬枚',
    Financing: '融資目標：',
    Financing1: '450萬美元',
    Minimum: '最低購買量：',
    pieces: '10000枚',
    Description: '通證說明：',
    Description1:
      'KYS 是整個KYS商業體的價值承載媒介，應用於交易、流通、獎懲、投票、分紅等各個環節，也是各個參與者在商業體系中權益比例的憑證。',
    Distribution: '通證分配',
    // Total: '通證總供應量——10億枚',
    Distribution1: '基金留存5%',
    Distribution2: '商業生態88%',
    Distribution3: '研發團隊4%',
    Distribution4: '私募投資3%',
    problem: 'Genesis 常見問題',
    core: 'Genesis 的核心原理是什麼？',
    core1: '一是，依托區塊鏈技術，踐行公平、可信的價值社會；',
    core2: '二是，創新社會化組織，搭建共生、共贏的生態關係。',
    commercial: 'Genesis 的項目團隊有哪些？',
    commercial1:
      'Genesis研發成員來自矽谷、華爾街、清華大學、英國帝國理工大學等知名區塊鏈科研團隊，全面優化各項公鏈性能。',
    commercial2: '同時，區塊鏈溯源系統能夠全流程記錄，確保產品品質可查證。',
    commercial3: '此外，通過優化商業模式與消費激勵機制相結合，能夠實現消費者樂於消費、敢於消費，極大降低企業庫存。',
    advantages: 'Genesis 具有哪些生態價值？',
    advantages1:
      'Genesis服務於去中心化商業運行、去中心化組織搭建、去中心化金融流通等各個領域，滿足NFT交易、元宇宙構建，與其他技術融合等，能夠為萬物互聯的智聯網時代提供底層技術支撐。',
    services: 'Genesis 的願景是什麼？',
    services1: '為全球泛生態體系架設大規模商用計算機；',
    services2: '為價值互聯網時代搭建去中心化運行平台。',
    vision: 'KYS的願景是什麼？',
    vision1: '打破資本主義商業桎梏，消除寡頭壟斷',
    vision2: '開創社會主義商業文明，打造共富經濟',
    Consultant: 'Genesis 團隊顧問',
    Partner: 'Genesis 合作夥伴',
    touch: '聯繫Genesis',
    subscription: '訂閱',
    butGNS: '購買 KYS 代幣',
    home: '首頁',
    RouteMap1: '路線圖',
    'Tech-Advantage': '技術優勢',
    API: 'API',
    issuance: 'KYS發行',
    loginStatus: '登錄/註冊',
    days: '天',
    hours: '時',
    minutes: '分',
    seconds: '秒',
    Next_round: '下一輪',
    GENESIS_Token: 'GENESIS Token',
    Ticker: '代幣：',
    NetWork: '公鏈：',
    GENESIS_Chain: 'GENESIS Chain',
    Presale_Price: '匯率：',
    Presale_Starts: '預售啟動'
  }
}
