// 按需全局引入 vant组件
import Vue from 'vue'
import {
  Button,
  List,
  Cell,
  Tabbar,
  TabbarItem,
  Form, Field,
  Rate,
  NoticeBar,
  Swipe, SwipeItem,
  RadioGroup, Radio,
  NavBar, Toast,
  Icon, Tab, Tabs,
  Progress,
  Grid, GridItem,
  Uploader, Dialog,
  PasswordInput,
  NumberKeyboard,
  Overlay,
  Popup,
  Calendar, CellGroup,
  Loading, PullRefresh
} from 'vant'
Vue.use(PullRefresh)
Vue.use(Loading)
Vue.use(Popup)
Vue.use(CellGroup)
Vue.use(Calendar)
Vue.use(Overlay)
Vue.use(NumberKeyboard)
Vue.use(PasswordInput)
Vue.use(Dialog)
Vue.use(Uploader)
Vue.use(Progress)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Rate)
Vue.use(Button)
Vue.use(Cell)
Vue.use(List)
Vue.use(Tabbar).use(TabbarItem)
Vue.use(NoticeBar)
Vue.use(Form)
Vue.use(Field)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(NavBar)
Vue.use(Toast)
Vue.use(Icon)
