const api = {
  Register: '/user/public/register', // 注册
  Send: '/user/verification_code/send', // 发送邮箱验证码
  Login: '/user/public/login', // 登录
  Logout: '/user/public/logout', // 登录退出
  PasswordReset: '/user/public/passwordReset', // 用户密码重置-》登录时候忘记密码
  ChangePassword: '/user/profile/changePassword', // 用户密码修改-》登录状态必须传XX-Hd-Token
  UserInfo: '/user/profile/userInfo', // 获取用户基本信息
  Addpwd: '/user/pay_pwd/addpwd', // 添加支付密码
  Uppwd: '/user/pay_pwd/uppwd', // 修改支付密码
  Forgetpwd: '/user/pay_pwd/forgetpwd', // 验证码找回支付密码
  MyassetsList: '/wallet/cloud/lists', // 我的资产
  Transfer: '/wallet/account/transfer', // 提币
  Address: '/wallet/account/address', // 充值
  AccountInfo: '/wallet/account/info', // 获取提币时候余额和手续费
  Transactions: '/wallet/account/transactions', // 账户记录
  Crowlist: '/user/public/crowlist', // 众筹详情
  Postcrow: '/wallet/account/postcrow', // 众筹认购
  Enlang: '/user/public/enlang', // 语言切换
  subscribe: '/wallet/account/subscribe', // 订阅
  isUse: '/user/public/is_use' // 邮箱是否使用过
  // 'wallet/account/postcrow' //质押提交
}

export default api
