/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  // {
  // path: '/',
  // component: () => import('@/views/layouts/index'),
  // redirect: '/home',
  // meta: {
  //   title: '首页',
  //   keepAlive: false
  // },
  // children: [
  {
    path: '/',
    name: 'newHome',
    component: () => import('@/views/newHome/index'),
    meta: { title: '首页', keepAlive: false }
  },
  {
    path: '/crowd',
    name: 'newCrowd',
    component: () => import('@/views/newHome/crowd'),
    meta: { title: '众筹', keepAlive: false }
  },
  {
    path: '/my',
    name: 'newMy',
    component: () => import('@/views/newHome/my'),
    meta: { title: '我的', keepAlive: false }
  },
  {
    path: '/register',
    name: 'newRegister',
    component: () => import('@/views/newHome/register'),
    meta: { title: '注册', keepAlive: false }
  },
  {
    path: '/login',
    name: 'newLogin',
    component: () => import('@/views/newHome/login'),
    meta: { title: '登录', keepAlive: false }
  },
  {
    path: '/recordList',
    name: 'newRecordList',
    component: () => import('@/views/newHome/recordList'),
    meta: { title: '财务记录', keepAlive: false }
  },
  {
    path: '/reset_password',
    name: 'newResetPassword',
    component: () => import('@/views/newHome/resetPassword'),
    meta: { title: '修改密码', keepAlive: false }
  },
  {
    path: '/reset_pay',
    name: 'newResetPay',
    component: () => import('@/views/newHome/resetPay'),
    meta: { title: '修改支付密码', keepAlive: false }
  },
  {
    path: '/recharge',
    name: 'newRecharge',
    component: () => import('@/views/newHome/recharge'),
    meta: { title: '充值', keepAlive: false }
  },
  {
    path: '/mentionMoney',
    name: 'mentionMoney',
    component: () => import('@/views/newHome/mentionMoney'),
    meta: { title: '提币', keepAlive: false }
  }
  // {
  //   path: '/pledge',
  //   name: 'pledge',
  //   component: () => import('@/views/newHome/Pledge'),
  //   meta: { title: '质押理财', keepAlive: false }
  // }
  // -----------------
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: () => import('@/views/home/index'),
  //   meta: { title: '首页', keepAlive: false }
  // },
  // {
  //   path: '/award',
  //   name: 'award',
  //   component: () => import('@/views/home/award'),
  //   meta: { title: '今日排名奖', keepAlive: false }
  // },
  // {
  //   path: '/earnings',
  //   name: 'earnings',
  //   component: () => import('@/views/home/earnings'),
  //   meta: { title: '动态收益', keepAlive: false }
  // }
  // ]
  // }award.vue
]
