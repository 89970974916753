module.exports = {
  msg: {
    yqm: 'Invitation code:',
    yqurl: 'Invitation link:',
    Tokens: 'Tokens',
    Subscription: 'Subscription first round',
    progress: 'Sales progress of tokens',
    Total: 'Token amount for pre-sale', // 'Total number of issuances of this round:',
    subscribed: 'Total amount subscribed:',
    Endsale: 'End of the sales',
    AvailableBalance: 'Available balance:',
    Obtain: 'KYS balance', // 'Acquisition',
    willreceive:
      'Enter your subscription amount, and the system will automatically calculate the number of tokens you will receive',
    EnterYourAmount: 'Enter the amount of USDT/KYS you want to subscribe and calculate the equal KYS/USDT balance.', // 'Enter your amount, you want to donate and calculate the number of tokens you will receive.The calculator helps to convert the required currency into tokens',
    TokenSale: 'Tokenomics', // 'Token sale',
    ASDtoken1: 'I have read and agreed with this', // 'GS token sale ',
    ASDtoken2: 'KYS TOKEN PURCHASE AGREEMENT', // 'terms and conditions',
    Participate: 'Subcribe Now', // 'Participation',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    Y: ' In',
    Start: 'Start',
    End: 'End',
    Exchangerate: 'Exchange rate',
    TotalIssuance: 'Total number of issuances',
    TokenName: 'Name of tokens',
    TokenSymbol: 'Token symbol',
    TotalCirculation: 'Total circulation',
    TechnicalGroups: 'Technical group',
    Foundation: 'Foundation',
    PreSale: 'Advance sale',
    Presale1: 'Presale',
    Ecology: 'Ecology',
    Not1ASD: 'No less than 1KYS',
    Please: 'Please check the terms and conditions',
    payment: 'Enter the payment password',
    ConfirmPayment: 'Confirm the payment',
    Entered: 'Incorrect payment password',
    Insufficient: 'Insufficient account balance',
    Oversubscription: 'Oversubscribed, remaining available for subscription',
    Kaiyuan: 'CNFS',
    ended: 'The activity has not been opened or closed',
    MyAssets: 'My assets',
    withdrawalPage: 'withdrawal page',
    TotalAssets: 'Total assets:',
    Currency: 'Currency',
    Available: 'Available',
    Freeze: 'Freeze',
    Operation: 'Operation',
    Withdrawal: 'Withdrawal',
    FinancialRecords: 'records',
    // FinancialRecords: 'Financial records',
    Time: 'Time',
    Type: 'Type',
    Quantity: 'Number',
    Status: 'Status',
    SystemIncrease: 'System augmentatio',
    Recharge: 'Recharge',
    SuccessfulTransaction: 'Success of transaction',
    Transaction: 'Failure of transaction',
    ModifyLogin: 'Modify the login password',
    Modifypayment: 'Modify the payment password',
    Logout: 'Log out',
    DepositCurrency: 'Currency of recharge',
    ChainName: 'Name of chain',
    CopyAddress: 'Copy address',
    CopySuccess: 'Copy success',
    WarmReminder: 'Warm prompt:',
    minimum1: 'The minimum amount of recharge:',
    minimum2: 'less than the minimum amount of recharge will not be credited',
    retrieved:
      'Please not recharge any non-USDT assets to the above address; otherwise, the assets will not be retrieved.',
    losses:
      'Please fill in and carefully check the address, which is the only logo of your account, to avoid unnecessary losses.',
    WithdrawalCurrency: 'Currency of withdrawa',
    PleaseAddress: 'Please enter or paste the withdrawal address',
    AvailableAuantity: 'Withdrawal quantity:',
    HandlingFee: 'Service charge',
    ActualArrival: 'Actual receipt',

    minimumBalance1: 'The minimum quantity of withdrawal:',
    minimumBalance2: ',the maximum amount of withdrawal shall not exceed the account balance.',

    ConfirmWithdrawal: 'Confirm withdrawal',
    PleaseEnterAddress: 'Please enter the address',
    PleaseAmount: 'Please enter the address',
    InsufficientBalance: 'Insufficient balance',
    Withdrawal50: 'The withdrawal must be greater than 10',
    SuccessfulSubmission: 'Submit successfully',
    processing: 'Waiting process',
    ww: 'Revocation of withdrawal',
    All: 'All',
    to50: 'The quantity must be greater than or equal to 50',
    ResetLoginPassword: 'Reset the login password',
    changePaymentPassword: 'change payment password',
    loginPage: 'login page',
    IfYou: 'If you forget the password, we will send you the instruction through e-mail to reset the password',
    YourEmail: 'Your e-mail address',
    CodeYouReceived: 'Enter the verification code you received',
    GetVerificationCode: 'Get the verification code',
    SetNewPassword: 'Set your new password',
    OK: 'Confirm',
    BackToLogin: 'Back to login',
    PasswordReset: 'Password reset successfully, please log in with the new password',
    UserNot: 'The user does not exist',
    Login: 'Login',
    Welcome: 'Welcome to log in Genesis',
    YourMail: 'Please enter your e-mail address', // 'Your e-mail',
    YourLoginPassword: 'Please enter your login password', // 'Your login password',
    LogIn: 'Login',
    NoAccountYet: 'No account yet?',
    RegisterNow: 'Register',
    ForgotPassword: 'Forget password',
    IncorrectPassword: 'Incorrect password',
    Register: 'Register',
    Create: 'Establish a new Serenity account',
    YourName: 'Your name',
    YourMail1: 'Your e-mail',
    verificationCode: 'Enter the verification code you received',
    PleaseMail: 'Please enter your mail',
    GetVerificationCode1: 'Get the verification code',
    SetLogin: 'Set up your login password',
    RepeatPassword: 'Repeat your login password',
    Setpassword: 'Set up your payment password',
    SetInvitationCode: 'Please enter the invitation code',
    agreement: 'I have agreed the terms and conditions as well as the privacy agreement',
    CreateAccount: 'Create an account',
    Verification: 'The verification code has been sent, please remember to check',
    PleaseVerificationCode: 'Please enter your verification code',
    plusLetters: 'The password must consist of 6-12 digits plus letters',
    ReEnter: 'Inconsistent password entered again',
    PleaseSet: 'Please set up the login password first',
    passwordAgain: 'Please enter the login password again',
    PleaseFirst: 'Please set up the payment password first',
    InvitationCode: 'Please set the invitation code first',
    PleaseName: 'Please enter your name',
    Incorrect: 'Incorrect verification code',
    exists: 'This account already exists',
    Registered: 'Registered successfully',
    PleasePaymentPassword: 'Please set up your new payment password',
    Modification: 'Confirm modification',
    ResetPaymentPassword: 'Reset the payment password',
    Team: 'Team',
    WhitePaper: 'White paper',
    Language: 'Language',
    Traditional: 'Traditional Chinese',
    English: 'English',
    Solution: 'Solution',
    Platform: 'Platform',
    PlatformPage: 'Platform page',
    banner1: 'Leading the next generation of distributed network storage',
    banner:
      'The Genesis pan-commercial ecosystem is a composite of the blockchain business system and digital asset trading system. The pan-commercial ecosystem gradually involving various industries with e-commerce as the core and blockchain technology as the underlying architecture provides the underlying technical support for the development of the whole industry, realizes the digitization and tokenization of the real industrial economy, and solves the problems such as asset on-chain and business model innovation.',

    ApplicationModule: 'Application module',
    ApplicationModuleTitle:
      'The Genesis pan-commercial ecosystem consists of multiple application modules that jointly carry the value of the entire business system, covering multiple fields of product traceability, supply chain finance, and Internet of Things transactions.',
    ApplicationModule1: 'Commercial application value of Genesis',
    ApplicationModule2:
      'Genesis will give fully play in e-commerce industry, product traceability, supply chain finance, logistics system, Internet of Things ecology, asset on-chain, electronic data processing (TPS) and consumer entertainment. Basic functions such as payment, consumption, digital currency, product security and confirmation of commodity rights are all optimized.',
    ApplicationModule3: 'Application of product traceability application system',
    ApplicationModule4:
      'With the help of blockchain technology, the product traceability system can realize trust sharing among brand owners, distributors, retailers, consumers, regulatory authorities and third-party testing agencies, and comprehensively improve the brand, efficiency, experience, regulatory and overall supply chain profitability.',
    ApplicationModule5: 'Application of Internet of things trading system',
    ApplicationModule6:
      'Internet of Things data trading platform is one of the cores of public chain application. In this platform, equipment manufacturers can establish channels for data collection and sales data; equipment users can provide personal data through this channel to gain benefits; data purchasers, such as advertisers can also use this platform to accurately locate users, get user profile data, and achieve the purpose of communication efficiently and at a lower price.',
    ApplicationModule7: 'Application of supply chain finance',
    ApplicationModule8:
      'Based on the distributed storage function of the blockchain technology, the application system of supply chain finance naturally has the characteristics of information not easy to be tampered with, decentralization, openness and visualization, which can effectively solve many pain points existing in the traditional supply chain finance, and help the supply chain finance break the bottleneck, innovate and develop.',
    theSuccess: 'Participate in the success',
    Acquiring: 'Buy KYS Token',

    SystemCore: 'System core',
    SystemCore1:
      'The business system of Genesis is a complete ecology, including the public chain, business system, Token governance mechanism, etc. In a big commercial ecosystem, the overall structure is divided into three parts, including the client side, Totem Center, Genesis, and Blockchain Adapter.',
    SystemCore2:
      'Genesis (Genesis Blockchain) is an open source public blockchain platform with smart contract functionality, which provides the decentralized Virtual Machine to handle peer-to-peer contracts through its proprietary cryptocurrency, TOM tokens.',
    SystemCore3:
      'The blockchain e-commerce system is a valued Internet e-commerce platform based on blockchain technology. Genesis as the underlying network of the business network, the whole process of traceable business system is achieved through business flow, information flow, capital flow, and logistics on the chain.',
    SystemCore4:
      'GDEX is a token exchange protocol based on Genesis, which is decentralized, and a set of contracts deployed on Genesis, and all transactions are carried out on the chain. Like other DEX, it can be freely deposited in tokens for exchange and can be withdrawn freely, without registration, authentication and extraction restrictions of a centralized exchange.',
    SystemCore5:
      'DWallet is the infrastructure of the blockchain world. The wallet is a system storing and managing the private key. DWallet is more like a keycase than a wallet. To sum up, centralized wallet is a place storing assets, and DWallet is a place storing the private key. Completely different properties result in completely different product forms. ',
    SystemCore6:
      'Digital asset payment system is an intelligent payment tool that is compatible with multiple digital assets, which provides a convenient and instant intelligent payment channel to merchants, consumers and bank terminals in the era of digital economy.',
    LearnMore: 'Learn more',
    Decentralized: 'Distributed cloud services',
    Backend: 'Fog computing back end',
    Market: 'The market based on the smart contract',
    GetPower: 'Get the power',
    GiveStrength: 'Give the power',
    Purchase: 'Purchase',
    Applicationof: 'Application of Internet of things trading system',
    benefits:
      'With the help of blockchain technology, the product traceability system can realize trust sharing among brand owners, distributors, retailers, consumers, regulatory authorities and third-party testing agencies, and comprehensively improve the brand, efficiency, experience, regulatory and overall supply chain profitability. ',
    Application: 'IoT transaction system application',
    LowerPrice:
      'Internet of Things data trading platform is one of the cores of public chain application. In this platform, equipment manufacturers can establish channels for data collection and sales data; equipment users can provide personal data through this channel to gain benefits; data purchasers, such as advertisers can also use this platform to accurately locate users, get user profile data, and achieve the purpose of communication efficiently and at a lower price.',
    Supply: 'Application of supply chain finance',
    Innovatively:
      'Based on the distributed storage function of the blockchain technology, the application system of supply chain finance naturally has the characteristics of information not easy to be tampered with, decentralization, openness and visualization, which can effectively solve many pain points existing in the traditional supply chain finance, and help the supply chain finance break the bottleneck, innovate and develop. ',

    PublicChainRules: 'Public chain rules',
    PublicChainRules2: 'Open internet',
    PublicChainToken2: 'Network upgrade',
    ConstantPass2: 'Developer tools',
    PublicChainRules1:
      'In order to increase the degree of decentralization and enhance the security of customer assets as well as the credibility of GENESIS pan-commercial ecosystem, the GENESIS pan-commercial ecosystem will launch the GENESIS with cross-chain technology online in the future. The GENESIS public chain will serve as a bridge to connect various network nodes and provide node members with real-time clearing, on-chain settlement and asset on-chain services.',
    PublicChainToken: 'Public chain tokens',

    PublicChainToken1:
      'Genesis Foundation is responsible for operating and managing KYS tokens (Genesis coin), and the overall circulation system includes the issuance, distribution, circulation and maintenance of KYS tokens (Genesis coin). With the commercial network as the carrier, KYS tokens (Genesis coin) are circulated in rewards and punishments, voting, dividends and other application scenarios, which have become the value target and equity voucher of each trading link in the system.',
    ConstantPass: 'Constant tokens',

    ConstantPass1:
      'Constant tokens are price-stable digital tokens that facilitate commodity trading issued through a corresponding mechanism. As the general equivalent of payments between consumers and merchants, the constant tokens are used to meet the operation requirements of the Genesis pan-commercial ecosystem, so as to maintain the smooth operation of the Genesis pan-commercial ecosystem.',

    assetOnChainServices:
      'In order to improve the degree of decentralization, enhance the security of customer assets and the credibility of the Kaiyuan public chain pan-commercial ecosystem, the Kaiyuan public chain pan-business ecosystem will launch the Kaiyuan public chain with cross-chain technology in the future. The public chain will serve as a bridge to connect various network nodes and provide node members with real-time clearing, on-chain settlement and asset on-chain services.',
    TeamAndConsultants: 'Teams and consultants',
    Together: 'Values unite us',
    Officer: 'CEO (Chief Executive Officer)',
    PhD: 'Doctor of Electronic and Communication Engineering, University of Pennsylvania, USA;',
    Former: 'Former Chief Information Officer of Yahoo;',
    Founder: 'Founder and CEO of Genesis Lab;',
    Laboratory: 'Core member of the Transnational Joint Technology Innovation Laboratory in Silicon Valley;',
    companies: 'More than 15 years of IT executive experience in top 500 multinational companies.',
    OperatingOfficer: 'COO (Chief Operating Officer)',
    Business: 'Master of Business Administration, School of Business, New York University; ',
    CoFounder: 'Co-founder of Genesis Lab; ',
    America: 'Former Chief Operating Officer of Bank of New York Mellon North America; ',
    Institutions: 'More than 10 years of experience in operation and management of fintech institutions.',
    Technology: 'CTO (Chief Technology Officer)',
    experience:
      'Master of Computer Science from Stanford University, 16 years of professional programming experience; ',
    Express: 'CTO and Architect of American Express; ',
    community: 'Early core member of the Bitcoin online community; ',
    world: 'Core member of many blockchain technology labs around the world.',
    FinancialOfficer: 'CFO Chief Financial Officer',
    Double: 'Double Bachelor of Finance and Business Administration, University of Toronto, Canada;',
    Citibank: 'Worked in Citibank, N.A. as Senior Risk Manager and Financial Expert;',
    ChiefFinancial: 'Chief Financial Adviser of many internationally renowned financial institutions;',
    Alliance: 'Core member of Silicon Valley Fintech Alliance.',
    CIOChief: 'CIO (Chief Information Officer)',
    Major: 'Major of Marketing Management, University of Portsmouth, UK; ',
    conceptualDesign: 'Focus on product experience promotion and innovative conceptual design; ',
    Product20: 'Product experience officer of more than 20 large multinational enterprises; ',
    SeniorConsultant: 'Senior Consultant at the Royal Federation of Business.',
    ChiefEconomist: 'Chief Economist',
    Birmingham: 'Master of University of Birmingham, UK;',
    PCCW: 'Worked in Microsoft, PCCW and other internationally renowned enterprises;',
    Investment: 'Core member of Singapore Blockchain Application and Investment Institute.',
    Distinguished: 'Distinguished Professor at Blockchain Business School in Singapore.',
    CSOChief: 'CSO (Chief Strategy Officer)',
    Minnesota: 'Doctor of Business Administration, University of Minnesota; ',
    StrategicManagement: 'Strategic Management Training Specialist, International Business School of Sao Paulo; ',
    FormerChief: 'Former Chief Strategy Officer of Strategy Committee, Bank of Safra.',
    CISO: 'CISO (Chief Information Security Officer)',
    Computer: 'Major of Computer Science, Columbia University, USA; ',
    CISSP: 'CISSP International Information System Security Certification Expert; ',
    audit: 'Former head of security audit of Telus Security Lab and Sybase database; ',
    Pacific:
      "Chief Information Security Officer of one of the world's top 500 enterprises in Singapore and Asia Pacific.",
    haveAccount: 'Already have an account?',
    immediately: 'Login immediately',
    agreed: 'I have agreed to',
    termsConditions: 'terms and conditions',
    and: ' as well as the ',
    PrivacyAgreement: 'Privacy agreement',
    retransmit: 'retransmit',
    PleaseExchange: 'Please enter for exchange',
    MinExchangeUsdt: 'Hello! The minimum subscription amount for the seed round starts from 300000 USDT',
    paymentCode: 'Please enter your payment code',
    theTerms: 'Please confirm the terms',
    PleaseEmailAddress: 'Please enter the correct email address',
    NoRecord: 'No record',
    Mentionddress: 'Mention money address',
    MentionCurrency: 'Mention a currency',
    LongSave: 'Long press the QR code to save',
    loading: 'loading...',
    RefreshSuccess: 'Refresh the success',
    ReleaseRefresh: 'Release to refresh...',
    incorrect: 'The mailbox format is incorrect',
    communicationsAtAnyTime:
      'By submitting your email, you are consenting to CNFS using your personal information to contact you about our products. You may unsubscribe from these communications at any time.',
    SUBMIT: 'SUBMIT',
    Email: 'Email',
    Subscribe: 'Subscribe to the newsletter to hear about CNFS updates and events.',
    subscribeBtn: 'subscribe',
    Chain: 'CNFS',
    round1: 'first round',
    round2: 'second round',
    round3: 'third round',

    civilization: 'Commercial Metaverse',
    civilization1: '——Decentralized Economic System',
    business:
      'This is an industrial revolution based on the integration of blockchain and multiple technologies. By focusing on innovative display, organizational structure, and business model, Genesis creates a new decentralized economic system—— Commercial Metaverse.',
    era: 'One dream, One hope of an era',
    mechanism:
      'A brand-new business ecology dedicated to breaking the shackles of capitalist commerce, sparing no efforts to promote the birth of a fairer social distribution mechanism.',
    example1: 'Taking over three years to reshape the economic model of business field. ',
    example2: 'Committed to building a shared economy and mutual win system for the whole people.',
    example3:
      'The combination of advanced technology and concepts promotes the deep empowerment of the real industry with blockchain technology.',
    AboutGNS: "Genesis Blockchain 3.0 Era's Preeminent Integrator",
    AboutGNS1: "The World's First Large-scale Commercial Computer",
    industry:
      'As an outstanding integrator in the blockchain 3.0 era, Genesis is committed to realizing a global decentralized, ownerless, universal cloud operating system, born for the execution of smart contracts, and carrying the rapidly growing global digital economy.',
    Integrated:
      'Compared with the representative, Ethereum of Blockchain 2.0. Genesis has higher scalability, faster transaction speed, more stable full-node operation, stronger security, more reasonable transaction cost. These innovative and excellent indicators will make Genesis truly efficient operating in the fields of DApp, DeFi, NFTs, distributed storage, supply chain finance, and certificate deposits.',
    system: 'Decentralized Organization System',
    Distribution: 'Distribution Mechanism Shared By All',
    Function: 'Genesis Ecology',
    Computing: 'Business',
    Commercial: 'DApp',
    Pledge: 'DeFi',
    Asset: 'Metaverse',
    RouteMap: 'Genesis Road Map',
    phase1: '2018', // Q2 2018 Initiate R&D
    phase2: '2022', // Q1 2022 Testnet Launch
    phase3: '2023', // Q2 2022 Mainnet Launch
    phase4: '2023', // Q2 2022 Financial Ecology Launch
    phase5: '2024', // Q3 2022 Business Ecology Launch
    phase1Info: 'Including underlying logic setup and substantive technical development of the public blockchain.',
    phase2Info:
      'After the technical team completes the basic internal testing, invite some of the previous participating teams to conduct performance testing.',
    phase3Info:
      'The final launch time of the mainnet may be slightly adjusted according to the actual testing situation of the testnet.',
    phase4Info:
      'Financial Ecology refers to the ecological modules such as decentralized wallet and DEX that need to be deployed simultaneously after the launch.',
    phase5Info:
      'Business ecology refers to the ecological modules such as decentralized mall, NFT platform, and GameFi that are deployed on Genesis.',
    Resources: 'Genesis Technology Advantages',
    Resources1: 'Consensus Mechanism',
    Resources2: 'High Scalability',
    Resources3: 'Cluster Effect',
    Resources4: 'Cross-chain Compatibility',
    Resources5: 'High-speed Settlement',
    Resources6: 'Ecological Support',
    Resources1Info:
      'Genesis’s original Delegated Proof of Consumption (DPoC) mechanism ensures that every participant in the ecology can obtain block rewards from Genesis.',
    Resources2Info:
      'Genesis is compatible with the Ethereum virtual machine (EVM), and all smart contracts running based on Ethereum can achieve efficient operation on Genesis.',
    Resources3Info:
      "Genesis's original heterogeneous composite chain technology, constructing a huge public blockchain cluster, can expand the performance and functions of the Genesis infinitely, and the sidechains and the subchain does not occupy the performance of the Genesis.",
    Resources4Info:
      'Genesis adopts the most advanced cross-chain technology at present, and all the certificates on other public blockchains can be circulated and traded on Genesis across the chain.',
    Resources5Info:
      'Genesis generates a block every 6 seconds, and the settlement TPS = 5000, which is far ahead in the industry.',
    Resources6Info:
      'Genesis adopts the best DPoC consensus mechanism and deeply integrates the physical industry, and the development of Genesis nodes has a self-fission effect.',
    SmartApi: 'Genesis API',
    Mall: 'DApp Mall ',
    Wallets: 'Decentralized Wallet',
    Exchange: 'Decentralized Exchange',
    Mining: 'NFT Trading',
    Redemption: 'Metaverse',
    Sales: 'Sales of Tokens',
    name: 'Name: ',
    Stock: 'Stock code:',
    World: 'Crypto World',
    TokenImage: 'Token image:',
    Price: 'Price before mainnet launch:',
    dollar: 'US do',
    Expected: 'Expected after the mainnet goes live:',
    Maximum: 'Maximum sale quantity:',
    Maximum1: '30 million pieces',
    Financing: 'Financing target:',
    Financing1: 'USD 4.5 million',
    Minimum: 'Minimum amount purchas:',
    pieces: '10000 piece',
    Description: 'Token Description:',
    Description1:
      'KYS is the value-carrying medium of the entire KYS business entity. It is used in various links such as transactions, circulation, rewards and punishments, voting, and dividends. It is also a proof of the proportion of rights and interests of each participant in the business system.',
    Distribution: 'Token’s Distribution',
    // Total: 'Total supply of Tokens- 1 billion ',
    Distribution1: 'Fund Retention 5%',
    Distribution2: 'Business Ecology 88% ',
    Distribution3: 'R & D team 4% ',
    Distribution4: 'Private Equity Investment 3%',
    problem: 'Genesis FAQ',
    core: 'What is the core principle of Genesis?',
    core1: 'First, relying on blockchain technology to practice a fair and credible value society.',
    core2: 'Second, to innovate social organizations and build a symbiotic and win-win ecological relationship.',
    commercial: 'What are the project teams of Genesis?',
    commercial1:
      'Genesis R&D members come from well-known blockchain research teams such as Silicon Valley, Wall Street, Tsinghua University, and Imperial College London, and fully optimize the performance of Genesis.',
    commercial2:
      'At the same time, the blockchain traceability system can record the entire process to ensure that the product quality can be verified. ',
    commercial3:
      'In addition, through the combination of optimized business models and consumption incentive mechanisms, consumers can be willing to consume and dare to consume, and greatly reduce corporate inventories. ',
    advantages: 'What ecological value does Genesis have?',
    advantages1:
      'Genesis serves various fields such as decentralized business operation, decentralized organization construction, decentralized financial circulation, etc. It satisfies NFT transactions, metaverse construction, and integration with other technologies. Genesis can provide underlying technical support for the era of Internet of Everything.',
    // advantages2:
    // 'At the same time, the project connects resources in various fields such as Medical beauty, Home furnishing, Tea industry, general health, marine economy, automobile, etc., and the project online traffic is expected to reach 5 million+. ',
    services: 'What is the vision of Genesis?',
    services1: 'Set up large-scale commercial computers for the global pan-ecosystem.',
    services2: 'Build a decentralized operation platform for the era of the Internet of Value (IoV).',
    vision: 'What is the vision of KYS?',
    vision1: 'Break the shackles of capitalist business and eliminate oligopoly ',
    vision2: 'Create a socialist commercial civilization and build an economy of mutual prosperity',
    Consultant: 'Genesis Team',
    Partner: 'Genesis Partners',
    touch: 'Contact Genesis',
    subscription: 'Subscription',
    butGNS: 'Buy KYS tokens',
    home: 'Home',
    RouteMap1: 'Road Map',
    API: 'API',
    'Tech-Advantage': 'Tech-Advantages',
    issuance: 'KYS issuance',
    loginStatus: 'Log/Register',
    days: 'Day',
    hours: 'Time',
    minutes: 'Minute',
    seconds: 'Second',
    Next_round: 'Next round',
    GENESIS_Token: 'GENESIS Token',
    Ticker: 'Ticker: ',
    NetWork: 'Network: ',
    GENESIS_Chain: 'GENESIS Chain',
    Presale_Price: 'Presale Price: ',
    Presale_Starts: 'Presale Starts'

    // ---------------------------------
    // ---------------------------------
    // ---------------------------------
    // ---------------------------------
    // ---------------------------------
    // ---------------------------------
    // ---------------------------------
    // ---------------------------------
    // ---------------------------------
    // ---------------------------------
    // ---------------------------------
    // ---------------------------------
    // ---------------------------------
  }
}
