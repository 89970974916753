import axios from 'axios'
import store from '@/store'
import { Toast } from 'vant'
import Router from '@/router/index'
import api from '../api'
// 根据环境不同引入不同api地址
import { baseApi } from '@/config'
// create an axios instance
const service = axios.create({
  baseURL: baseApi, // url = base api url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request拦截器 request interceptor
service.interceptors.request.use(
  config => {
    // 不传递默认开启loading
    if (!config.hideloading) {
      // loading
      Toast.loading({
        forbidClick: true
      })
    }
    if (localStorage.getItem('token')) {
      config.headers['XX-Token'] = localStorage.getItem('token')
    }
    config.params = {
      lang: localStorage.getItem('Language') === 'zh' ? 'zh-cn' : 'en-us',
      ...config.params
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
// respone拦截器
service.interceptors.response.use(
  response => {
    Toast.clear()
    const res = response.data
    console.log(res)
    if (res.status && res.status !== 200) {
      // 登录超时,重新登录
      if (res.status === 401) {
        store.dispatch('FedLogOut').then(() => {
          location.reload()
        })
      }
      return Promise.reject(res || 'error')
    } else {
      if (res.code === 10001) {
        localStorage.clear()
        Toast(res.msg)
        Router.push({ path: '/login' })
        return Promise.reject(res)
      } else {
        return Promise.resolve(res)
      }
    }
  },
  error => {
    Toast.clear()
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export const ajaxGet = async (url, params = {}) => await doAjax(url, params, 'get')
export const ajaxPost = async (url, params = {}) => await doAjax(url, params, 'post')
export const ajaxDelete = async (url, params = {}) => await doAjax(url, params, 'delete')
export const ajaxPut = async (url, params = {}) => await doAjax(url, params, 'put')

function doAjax(url, params = {}, type) {
  const payload = ['get', 'delete'].includes(type)
    ? {
        params
      }
    : params
  return service[type](api[url], payload)
}

export default service
