module.exports = {
  msg: {
    one: '사상 최초, 보안 및 감사를 거친 스마트 계약 플랫폼',
    meet: '미래를 만나다',
    meetExplain: '미래를 만나다 72시간 카운트다운 추첨. 72시간동안 노드입장이 없을 경우, 시간이 0으로 줄어들어 미래를 만나다 추첨이 진행됩니다. 하지만 ETH가 하나씩 추가될 때마다 2시간 연장되며 최대 시간은 72시간을 넘지 않습니다. 본 상은 최대 5000개의 ETH가 누적될 수 있습니다. 본 상은 일단 추첨되면 투자 비중에 따라 마지막으로 투자한 100개 노드가 상금을 획득하게 됩니다.',
    countdown: '카운트다운',
    getToken: '토큰 획득',
    currentStage: '현재단계',
    currentStage2: '에인절 펀드，',
    Remaining: '현재단계 잔여한도',
    ObtainedToken: '토큰 획득',
    TokenBalance: '토큰 잔액',
    AccountBalance: '계좌 잔액',
    buy: '구매',
    participate: '참여하기',
    participateExplain1: '분산형 마케팅은 혁신적인 스마트 계약 기술을 기반으로 합니다. ',
    participateExplain2: 'Vane프로젝트의 스마트 계약 코드가 완전히 공개되어， ',
    participateExplain3: '프로젝트의 보안 및 장기 작업에 대한 완벽한 신뢰를 얻을 수 있습니다.',
    game: '게임',
    account_balance: '계정 잔액',
    link: '링크',
    myReferral: '나의 추천인',
    myDirectReferrals: '나의 직접 추천 수량',
    myInvitationLink: '나의 초대 링크',
    myID: '나의 아이디',
    myAddress: '나의 주소',
    copyInvitationLink: '초대 링크 복사',
    Rules: '규칙',
    RulesPlay: '플레이 규칙',
    RulesExplain1: '게임에 참여하는 ETH는 금액과 시장 변화에 따라 최대 3배의 수익을 얻을 수 있습니다. 블록은 일일 정적배당으로 계산되며 배당률은 확대 배수의 약 5%입니다.',
    RulesExplain2: '동적으로는 배당 상, 레벨 상, 일일 랭킹 상, V5 가중 상 등 여러가지 종류의 인센티브를 수여합니다.',
    RulesExplain3: '토큰의 판매: 다원 생태, 지속적인 트래픽 유입 및 팬 흡입, 공감대 형성 및 가치 향상.',
    TodayAward: '오늘의 랭킹상',
    YesterdayRankingAward: '어제 랭킹상',
    YesterdayAward: '장려',
    Rank: '순위',
    Performance: '실적',
    ID: 'ID',
    ViewYesterdays: '어제 랭킹상 보기',
    Yesterday: '어제 랭킹상',
    Statistics: '데이터 통계',
    MyLevel: '나의 레벨',
    UnsettledEarnings: '미결산 수익',
    CashableIncome: '인출가능 수익',
    StaticIncome: '정적 수익',
    DynamicIncome: '동적 수익',
    SettledIncome: '결제된 수익',
    outNeeds: '동적 아웃 까지의 차이',
    balance: '결제',
    TotalTSokenSales: '토큰 매출 총수',
    TotalNumberBurned: '토큰 소각 총수',
    EntryFundsToday: '오늘 진입 자금',
    ViewDetailedData: '자세한 정보 보기',
    DirectNode: '총업적을 직공하다',
    RegionalPerformance: '빅 블록 실적',
    CommunityPerformance: '스몰 블록 실적',
    DividendAward: '배당상',
    LevelAward: '레벨상',
    DailyRankingsAward: '일일 순위상',
    commonProblem: '자주 묻는 질문',
    problem1: '1. 스마트 계약은 무엇이며 어떤 장점이 있는가?',
    answer1: '스마트 계약은 통화 블록체인스의 암호화에 사용되는 알고리즘으로, 우리로서는 스마트 계약을 만드는 데 주안점을 두고 있다.이러한 계약의 주요목적은 관계를 자동화하여 승낙이 자동적으로 집행될수 있는 기회를 주는것이다.',
    problem2: '2. 어떤 것이Vane?',
    answer2: '네이처 링크 (Vane)는 암호화 용도로 이용된 동호인들의 모임으로 특별한 권한이 없습니다.네이처 링크는 머니스트링에서 자동으로 작동되는 코드 중 하나인 전문용어로 스마트 링크로, 이는 가장 안전하고 유연하며 신뢰할 수 있는 텍스트링크로, 또는 계산기논리계산으로 구성되어 있습니다.구안 역시 세계적으로 처음 블록체인에서 지능계약 개념과 활용을 도입한 디지털 통화 중 하나다.암호 화폐와 같은 스마트 계약은 분산 시스템입니다.그것들은 엄격히 정해진 공정에 따라 개작될 가능성이 없다.스마트 계약은 모든 로코 코드가 블록체인에 설치돼 있고, 모든 계산은 전 세계 수백만 대의 컴퓨터가 진행하고 있다.스마트협약을 해킹하거나 프로그램을 정지시킬 위험이 없다.블록체인에서는 분산 저장되기 때문이죠.',
    problem3: '3. 누가 플랫폼을 운영하는가?',
    answer3: '네이처 링크 항목에는 관리자가 없습니다.한 창조자가 암호화 통화 블록의 사인에 계약 번호를 부탁했다.이후 스마트 계약은 전체 네트워크의 한 부분으로 부상했고 광부들의 지원을 받았다.스마트 협약을 변경하거나 파기할 권리가 없다.블록체인에서 이전 복사본과 일치하지 않기 때문에 허가받지 않은 변경 시도는 거부됩니다.',
    problem4: '4. 만약 프로젝트가 망할 경우, 저도 손해를 보나요?',
    answer4: '손해를 보지 않습니다! 스마트 계약은 절대적으로 투명하고 모든 데이터가 블록체인 체인에 기록됩니다. 사기나 왜곡행위가 없습니다. 따라서 이 프로젝트는 실패할 수 없습니다. 해당 블록체인이 존재하는 한 사이트가 다운되더라도 유효합니다.',
    yhzj: '은행자금',
    hysj: '계약감사',
    jrwmdb: '가입전보',
    logo: 'LOGO 다운로드',
    save: 'PNG로 저장',
    ReplicationSuccess: '복사 성공',
    notSufficientFunds: '잔액이 부족하다',
    xian: '구매범위 1~100',
    AfterTheStart: '인수 신청이 카운트다운을 시작하다',
    ForTheEnd: '앞다투어 사는 것이 마감되다',
    peopleCounting: '인원을 합산하다'
  }
}
