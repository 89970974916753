// 兼容 IE
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babelpolyfill
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import ElementUI from 'element-ui'
import VueScrollTo from 'vue-scrollto'
import VueI18n from 'vue-i18n'
import AOS from 'aos'
import VueBlu from 'vue-blu'
import App from './App.vue'
import router from './router'
import store from './store'
import { $cdn } from '@/config'
import { ajaxGet, ajaxPost, ajaxDelete, ajaxPut } from './utils/request'
import '@/plugins/vant'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/index.scss'
import '@/assets/css/register.scss'
import 'aos/dist/aos.css'
import './filters'
Vue.use(VueScrollTo)
Vue.use(ElementUI)
Vue.use(VueI18n)
Vue.use(AOS)
Vue.use(VueBlu)
Vue.prototype.$cdn = $cdn
const i18n = new VueI18n({
  locale: 'en', // 'zh', // 语言标识
  // this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    zh: require('./language/zh'), // 中文语言包
    en: require('./language/en'), // 英文语言包
    jp: require('./language/jp'), // 日语语言包
    kn: require('./language/kn'), // 韩语语语言包
    e: require('./language/e') // 俄语语言包
  }
})
Vue.prototype.$ajaxGet = ajaxGet
Vue.prototype.$ajaxPost = ajaxPost
Vue.prototype.$ajaxDelete = ajaxDelete
Vue.prototype.$ajaxPut = ajaxPut
Vue.config.productionTip = false

// import { baseUrl } from "./config/env.staging";
// Vue.prototype.baseUrl = baseUrl;
// console.log(baseUrl)

// import Web3 from 'web3'
// Vue.prototype.Web3 = Web3
// 定义时间格式全局过滤器
import moment from 'moment'
Vue.filter('dateFormat', function (daraStr, pattern = 'HH:mm:ss') {
  return moment(daraStr).format(pattern)
})
// if (!(location.host.indexOf('localhost') == -1 && location.host.indexOf('http://127.0.0.1') == -1)) {
//   window.console.log = () => {}
// }
new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: h => h(App)
})
