module.exports = {
  msg: {
    one: '歴史上初の安全で監査済みのスマートコントラクトプラットフォーム',
    meet: '未来に出会う',
    meetExplain: '「未来に出会う」宝くじまで72時間のカウントダウン、72時間以内にノードがない場合、時間はゼロになり、そして「未来に出会う」宝くじが抽せんされます。ただし、ETHごとに、2時間延長され、最大延長時間は72時間を超えません。この賞の最大累積額は5000ETHです。賞金が抽せんされると、投資の重みに比例して、最後に投資された100ノードからボーナスが獲得されます。',
    countdown: 'カウントダウン',
    getToken: '取得したトークン',
    currentStage: '現在の段階',
    currentStage2: 'エンジェルファンド、',
    Remaining: 'この段階での残りの最大額',
    ObtainedToken: '取得したトークン',
    TokenBalance: 'トークン残高',
    AccountBalance: '口座残高',
    buy: '購入',
    participate: '私は参加したいです',
    participateExplain1: '分散型マーケティングは、革新的なスマートコントラクトテクノロジーによってサポートされています。',
    participateExplain2: 'Vaneプロジェクトのスマートコントラクトコードは完全にオープンため、',
    participateExplain3: 'プロジェクトの安全性と長期性を安心してください',
    game: 'ゲーム',
    account_balance: '口座残高',
    link: 'リンク',
    myReferral: '私の推薦者は',
    myDirectReferrals: '私の直接プッシュの数',
    myInvitationLink: '私の招待リンク',
    myID: '私のID',
    myAddress: '私のアドレス',
    copyInvitationLink: '招待リンクをコピーします',
    Rules: '規則',
    RulesPlay: 'ルール',
    RulesExplain1: 'ゲームに参加するETHは最大3倍のリターンを獲得できます。ゲームに参加した金額と市場の変化に応じて、ブロックは1日の静的配当に決済され、配当率は拡大後約5‰になります。',
    RulesExplain2: '動態にはボーナス賞、レベル賞、ランキング賞、V5加重賞があります。 ',
    RulesExplain3: 'トークン販売：多様なエコロジー、ファンを引き付け続け、コンセンサスを構築し、価値を高めます。',
    TodayAward: '今日のランキング賞',
    YesterdayRankingAward: '昨日のランキング賞',
    YesterdayAward: '奖励',
    Rank: 'ランク',
    Performance: 'パフォーマンス',
    ID: 'ID',
    ViewYesterdays: '昨日のランキング賞をチャックします',
    Yesterday: '昨日のランキング賞',
    Statistics: 'データ統計',
    MyLevel: '私のレベル',
    UnsettledEarnings: '未決済の収益',
    CashableIncome: '換金可能の収益',
    StaticIncome: '静的収入',
    DynamicIncome: '動的収入',
    SettledIncome: '決済済みの収入',
    outNeeds: '动静出局还差',
    balance: '決算',
    TotalTSokenSales: 'トークン販売の総数',
    TotalNumberBurned: 'トークン廃棄の総数',
    EntryFundsToday: '今日に投入された資金',
    ViewDetailedData: '詳細データをチャックします',
    DirectNode: '総業績を直押しする',
    RegionalPerformance: '大エリアのパフォーマンス',
    CommunityPerformance: '小エリアのパフォーマンス',
    DividendAward: 'ボーナス賞',
    LevelAward: 'レベル賞',
    DailyRankingsAward: 'デイリーランキング賞',
    commonProblem: 'よく問い合わせ',
    problem1: '1. スマートコントラクトとは何ですか？その優位性は何ですか？',
    answer1: 'スマートコントラクトとは、暗号通貨ブロックチェーンにおけるアルゴリズムのことで、私たちの場合は、スマートコントラクトを作成できるCoinanを第一候補としています。 このような契約の主な目的は、関係性の自動化であり、約束の実行を自動化する機会を与えます。',
    problem2: '2. Vaneとは何ですか？',
    answer2: 'ネイチャーリンクは、コミュニティのメンバーであり、特別な特権を持たない暗号通貨愛好家のグループによって作成されました。 ネイチャーリンクは、Coinanチェーン上で自動的に実行されるように構築されたコードの文字列です ---- 技術用語はスマートコントラクトと呼ばれ、最も安全で柔軟性と信頼性の高い種類のコードアルゴリズムまたはブロックチェーン上に構築されたコンピュータロジック操作です。 また、クリプトカレンシーは、ブロックチェーン上でのスマートコントラクトの概念と応用を世界で初めて導入したデジタル通貨の一つでもあります。 スマートコントラクトは、暗号通貨のように流通しています。 確立された通りに厳密に動作し、改ざんされる可能性はありません。 スマートコントラクトの論理コードはすべてブロックチェーン上に置かれ、すべての計算は世界中の数百万台のコンピュータによって行われます。 これにより、スマートコントラクトがハッキングされてプロジェクトが停止するリスクがないことを保証します。 これは、ブロックチェーン上に分散して保存されているからです。',
    problem3: '3. 誰かプラットフォームを操作しますか？',
    answer3: 'ネイチャーリンクプロジェクトには管理者はいません。 暗号通貨ブロックチェーンCoinanに契約コードをアップロードしたクリエイターがいました。 それ以来、スマートコントラクトはネットワーク全体の一部となり、採掘者に支持されるようになりました。 スマートコントラクトの運用に影響を与えたり、解除したり、解約したりする権利は誰にもありません。 不正に変更しようとすると、ブロックチェーン内の前のコピーとの不一致により拒否されます。',
    problem4: '4. プロジェクトがクラッシュしたときに、私は損になりますか？',
    answer4: 'いいえ！スマートコントラクトは完全に透明的であり、すべてのデータはブロックチェーンチェーンに記録されます。詐欺は一切ありません。プロジェクトは失敗することはありません。サイトが閉じていても、ブロックチェーンが存在する限り機能します。',
    yhzj: '銀行資金',
    hysj: 'コントラクト監査',
    jrwmdb: '私たちにご参加してください',
    logo: 'ロゴのダウンロード',
    save: 'pngとして保存します',
    ReplicationSuccess: '複製の成功',
    notSufficientFunds: '残高不足',
    xian: '購入範囲は1~100',
    AfterTheStart: '申し込み開始カウントダウン',
    ForTheEnd: '買占め終了',
    peopleCounting: 'にんずう'

  }
}
